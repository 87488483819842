<template>
  <div>
    <Pane />

    <div class="container">
      <a-form
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 12 }"
        :colon="false"
        @submit="handleSubmit"
        :form="form"
      >
        <a-form-item label="标题">
          <a-input
            v-decorator="[
              'title',
              { rules: [{ required: true, message: '请输入' }] },
            ]"
          />
        </a-form-item>
        <a-form-item>
          <span class="required" slot="label">正文</span>
          <div id="editor"></div>
        </a-form-item>

        <a-form-item label="分类">
          <a-radio-group
            v-decorator="[
              'category',
              { rules: [{ required: true, message: '请选择！' }] },
            ]"
            @change="onCategoryChange"
          >
            <a-radio
              v-for="item in categoryList"
              :key="item.value"
              :value="item.value"
              >{{ item.name }}</a-radio
            >
          </a-radio-group>
        </a-form-item>

        <a-form-item label="标签" v-if="tagList.length > 0">
          <a-radio-group
            v-decorator="[
              'tag',
              { rules: [{ required: true, message: '请选择！' }] },
            ]"
          >
            <a-radio
              v-for="item in tagList"
              :key="item.value"
              :value="item.value"
              >{{ item.name }}</a-radio
            >
          </a-radio-group>
        </a-form-item>

        <a-form-item label="置顶值">
          <a-input-number
            placeholder="置顶值越高，排名越靠前"
            style="width: 100%"
            v-decorator="['top']"
          />
        </a-form-item>

        <a-form-item>
          <span class="required" slot="label">封面图</span>
          <FileUpload
            uploadType="image"
            :multiple="false"
            @uploaded="uploaded"
            :imagePath="imagePath"
          />
        </a-form-item>

        <a-form-item label="状态">
          <a-radio-group
            v-decorator="[
              'status',
              { rules: [{ required: true, message: '请选择！' }] },
            ]"
          >
            <a-radio
              v-for="item in statusList"
              :key="item.value"
              :value="item.value"
              >{{ item.name }}</a-radio
            >
          </a-radio-group>
        </a-form-item>

        <a-form-item label="是否显示">
          <a-radio-group
            v-decorator="[
              'isShow',
              { rules: [{ required: true, message: '请选择！' }] },
            ]"
          >
            <a-radio
              v-for="item in booleanList"
              :key="item.value"
              :value="item.value"
              >{{ item.name }}</a-radio
            >
          </a-radio-group>
        </a-form-item>

        <div class="center">
          <a-space>
            <a-button htmlType="submit" type="primary">保存</a-button>
            <a-button @click="$close($route.path)">关闭</a-button>
          </a-space>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import watermark from "@/mixins/watermark";
import E from "wangeditor";
import FileUpload from "@/components/file-upload";
import { fetchDetail, edit } from "@/api/document";
import { mapGetters } from "vuex";

export default {
  name: "editDocument",
  mixins: [watermark],

  components: {
    FileUpload,
  },

  data() {
    return {
      form: this.$form.createForm(this),
      editor: null,
      tagList: [],
      id: null,
      doc: {}, // 正在编辑的文档
      imagePath: null, // 上传的封面图路径
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    categoryList() {
      return this.findDataDict("documentType");
    },
    statusList() {
      return this.findDataDict("status");
    },
    booleanList() {
      return this.findDataDict("boolean");
    },
  },

  mounted() {
    const editor = new E("#editor");

    editor.config.height = 400;
    editor.config.zIndex = 1;
    editor.config.placeholder = "";
    editor.config.uploadImgServer = "/api/common-service/file/upload";
    editor.config.uploadImgHeaders = {
      token: window.localStorage.getItem("token"),
    };
    editor.config.uploadFileName = "files";

    editor.config.uploadImgHooks = {
      customInsert: function (insertImgFn, result) {
        if (result && Array.isArray(result.data) && result.data.length > 0) {
          insertImgFn(result.data[0].completePath);
        }
      },
    };

    editor.create();
    this.editor = editor;
  },

  activated() {
    const { query } = this.$route;
    const { id } = query || {};

    if (id && this.id !== id) {
      this.id = id;
      fetchDetail({
        id,
      }).then((res) => {
        this.doc = res;
        this.imagePath = res.face;
        if (this.editor) {
          this.editor.txt.html(res.content); // 重新设置编辑器内容
        }

        this.form.setFieldsValue({
          title: res.title,
          top: res.top,
          status: String(res.status),
          isShow: String(res.isShow),
        });
      });
    }
  },

  beforeDestroy() {
    if (this.editor) {
      this.editor.destroy();
      this.editor = null;
    }
  },

  methods: {
    onCategoryChange(e) {
      const res = this.categoryList.find(
        (item) => item.value === e.target.value
      );
      this.tagList = res.children;
    },

    uploaded(res) {
      if (res.length > 0) {
        this.imagePath = res[0].completePath;
      }
    },

    handleSubmit(e) {
      e.preventDefault();

      const content = this.editor.txt.html();

      if (content.length === 0) {
        this.$message.error("请输入正文！");
        return;
      }

      if (!this.imagePath) {
        this.$message.error("请上传封面图！");
        return;
      }

      this.form.validateFields((err, values) => {
        if (!err) {
          edit({
            ...this.doc,
            ...values,

            content,
            face: this.imagePath,
            category: values.tag,
            tag: undefined,
          }).then(() => {
            this.$close(this.$route.path);
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 30px 164px;
}

.center {
  margin-top: 91px;
}
</style>
